<template>
  <div>
    <div class="bg-white margin-top-sm">
      <div class="bg-white padding-lr-xs">
        <div class="text-lg text-black padding-top-sm padding-bottom-xs">
          我的优惠券列表
          <el-button
            type="primary"
            class="margin-left-sm"
            size="small"
            icon="el-icon-sold-out"
            @click="onExchange"
            >兑换优惠券</el-button
          >
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="全部优惠券" name="ALL" />
          <el-tab-pane label="可用优惠券" name="TOUSE" />
          <el-tab-pane label="已使用优惠券" name="USED" />
          <el-tab-pane label="已过期优惠券" name="EXPRIED" />
        </el-tabs>
      </div>

      <div v-if="!isLoad && !couponsList.length">
        <el-result
          icon="warning"
          title="内容为空"
          subTitle="您没有该类型的优惠券信息 ~ "
        >
          <template slot="extra">
            <el-button type="primary" size="medium" @click="getCouponsList"
              >刷新试试</el-button
            >
          </template>
        </el-result>
      </div>
      <el-row>
        <el-col
          v-for="item in couponsList"
          :key="item._id"
          :xl="4"
          :lg="4"
          :md="6"
          :sm="8"
          :xs="24"
        >
          <CouponsCard :item="item" @remove="onRemove" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getCoupons } from "./service";
import CouponsCard from "./components/couponsCard.vue";
export default {
  components: { CouponsCard },
  data() {
    return {
      tabs: [
        {
          label: "可用优惠券",
          name: "1",
        },
        {
          label: "过期优惠券",
          name: "2",
        },
      ],
      activeName: "TOUSE",
      couponsList: [],
      total: 0,
      isLoad: true,
    };
  },
  watch: {
    activeName() {
      this.getCouponsList();
    },
  },
  created() {
    this.getCouponsList();
  },
  methods: {
    onExchange() {
      this.$prompt("请输入优惠券码", "使用优惠券码兑换", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[^\u4e00-\u9fa5]+$/,
        closeOnClickModal: false,
        inputErrorMessage: "不能包含汉字哦 ~",
      }).then(async ({ value }) => {
        await this.$api.exchangeCoupons({
          couponCode: value,
        });
        await this.onGetCouponsList();
        this.$message.success("优惠券兑换成功。");
      });
    },
    async getCouponsList() {
      this.isLoad = true;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const { data } = await getCoupons({
          condition: this.activeName,
        });
        this.couponsList = data.list;
        this.total = data.totalCnt;
        this.isLoad = false;
      } finally {
        loading.close();
      }
    },
    onRemove() {
      this.$confirm("删除优惠券后将无法找回哦 ~", "确定要删除该优惠券吗？", {
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>