 <template>
  <div class="coupons-wrap margin-xs">
    <div
      class="solid"
      :class="
        ['runOut', 'timeout'].includes(useStatus) ? 'bg-timeout-wrap' : ''
      "
    >
      <div class="text-center padding-tb-sm coupons-bg" :class="bgColor">
        <div v-if="activity.couponType === 0">
          <div class="margin-bottom-xs">
            <span class="price-number text-bold text-price"
              >{{ activity.price }}
            </span>
          </div>
          <div>
            <span class="cu-tag bg-white" v-if="activity.canOverlay"
              >可叠加</span
            >
            <span class="cu-tag radius bg-white">无门槛优惠券</span>
          </div>
        </div>
        <div v-else-if="activity.couponType === 1">
          <div class="margin-bottom-xs">
            <span class="text-price price-number text-bold">{{
              activity.price
            }}</span
            >券
          </div>
          <div>
            <span class="cu-tag bg-white" v-if="activity.canOverlay"
              >可叠加</span
            >
            <span class="cu-tag radius bg-white"
              >满{{ activity.threshold }}元可用</span
            >
          </div>
        </div>
        <div v-else-if="activity.couponType === 2">
          <div class="margin-bottom-xs">
            <span class="price-number text-bold">{{
              String(activity.discount).split("").join(".")
            }}</span
            >折
          </div>
          <div>
            <span class="cu-tag bg-white" v-if="activity.canOverlay"
              >可叠加
            </span>
            <span class="cu-tag bg-white"
              >{{ activity.couponType | couponName }}
            </span>
            <!-- <span class="cu-tag radius bg-white">最多减800元</span> -->
          </div>
        </div>
        <div class="margin-top-xs text-xs text-cut">
          {{ item.couponCode }}
          <a
            class="text-white el-icon-copy-document"
            v-clipboard:copy="item.couponCode"
            v-clipboard:success="() => $notify({ title: '优惠券码复制成功' })"
          />
        </div>
      </div>
      <div class="text-left padding-xs text-list text-sm">
        <div class="text-cut">
          <span>活动名：</span
          ><span class="text-gray">{{ activity.activityName }}</span>
        </div>
        <div>
          <span>券限制：</span
          ><span class="text-gray">{{
            couponsUserType(activity.customerType)
          }}</span>
        </div>
        <div class="margin-tb-m">
          <span>券领取：</span
          ><span class="text-gray">{{ item.getTime | yyyDayjs }}</span>
        </div>
        <div class="margin-tb-m">
          <span>券过期：</span
          ><span class="text-gray">{{ item.validTo | yyyDayjs }}</span>
        </div>
        <div>
          <span>券来源：</span
          ><span class="text-gray"
            >{{ ["", "系统派发", "用户领取", "已使用"][item.status] }}
          </span>
        </div>
      </div>
      <div class="text-center solid-top padding-tb-m">
        <span v-if="useStatus === 'runOut'" class="text-sm text-gray text-bold"
          >已使用</span
        >
        <span v-else class="text-red">
          <el-button
            size="mini"
            type="text"
            :disabled="useStatus === 'timeout'"
            @click="$router.push('/food')"
          >
            {{ useStatus === "timeout" ? "已过期" : "立即使用" }}</el-button
          >
        </span>
      </div>
      <div>
        <div
          class="tips-left"
          v-if="isExpire(item.validTo) && useStatus === 'use'"
        >
          <div class="tips"></div>
          <span class="text-white text-xs text">即将过期</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export const couponName = (value) =>
  ({ 0: "现金券", 1: "满减券", 2: "折扣券" }[value] || value);
export default {
  filters: {
    couponName,
    yyyDayjs(v) {
      return moment(v).format("YYYY-MM-DD");
    },
  },
  props: {
    item: {},
  },
  computed: {
    activity() {
      return this.item.activity || {};
    },
    bgColor() {
      if (["runOut", "timeout"].includes(this.useStatus)) return "bg-timeout";
      return {
        0: "bg-gradual-orange",
        1: "bg-gradual-purple",
        2: "bg-gradual-pink",
      }[this.activity.couponType];
    },
    useStatus() {
      if (this.item.status === 3) return "runOut";
      return moment(this.item.validTo).diff(moment(), "seconds") < 0
        ? "timeout"
        : "use";
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-timeout-wrap {
  color: #ccc;
  .text-gray {
    color: #ccc;
  }
  .bg-timeout {
    background-color: #e4e4e4;
    color: #f2f2f2;
    .cu-tag {
      color: #f2f2f2;
      background-color: #e4e4e4;
    }
  }
}
.coupons-wrap {
  position: relative;
  .overdue-coupons {
    .text-red,
    .text-list {
      color: var(--gray);
    }
  }
  .price-number {
    font-size: 50px;
    line-height: 1;
  }

  .tips-left,
  .tips-right {
    position: absolute;
    top: 0;
    z-index: 1;
    .tips {
      position: relative;
    }
  }
  .tips-left {
    left: 0;

    .tips:before {
      position: absolute;
      content: "";
      z-index: -1;
      border-color: red transparent transparent red;
      border-width: 30px 30px 30px 30px;
      border-style: solid;
    }
    .text {
      transform: rotate(-45deg);
      margin-top: 12px;
      margin-right: 5px;
    }
  }
  .tips-right {
    right: 0;
    display: none;
    .text {
      padding: 3px;
      color: var(--white);
      cursor: pointer;
    }
    .tips:before {
      position: absolute;
      content: "";
      z-index: -1;
      border-color: var(--gray) var(--gray) transparent transparent;
      border-width: 20px 20px 20px 20px;
      border-style: solid;
      right: 0;
    }
  }
}
.coupons-wrap:hover {
  .tips-left,
  .tips-right {
    display: inline;
  }
  .solid::after {
    border: 2px solid rgba($color: red, $alpha: 0.5);
  }
}
</style>